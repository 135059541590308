/**
 * Show user name and there role title
 * It utilizes Redux state for data.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const UserPofile = () => {
  const admin = useSelector((state) => state.admin);

  return (
    <section className="user-profile">
      <Container fluid className="p-0">
        <Row>
          <Col lg={12}>
            <div className="profile-wrapper d-flex justify-content-end align-items-center">
              <h6 className="user-name me-3 mb-0">
                {admin.name} <span className="role">({admin.rolePermission.title})</span>
              </h6>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UserPofile;
