/**
 * Refund Request
 * Display refund stats chart in percentage for approved refund against pending request.
 * It utilizes Redux state for stats data.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from 'react-redux/es/hooks/useSelector';

const RefundRequests = () => {
  const refund = useSelector((state) => state.refund);
  const [approvedPercentage, setApprovedPercentage] = useState(0);

  useEffect(() => {
    setApprovedPercentage(
      (refund.approvedRequest / (refund.approvedRequest + refund.pendingRequest)).toFixed(1) * 100
    );
  }, [refund]);

  return (
    <section className="refund-request position-relative">
      <h4 className="text-rotate text-white mb-sm-0 mb-3">Refund Requests</h4>
      <div className="progress-wrapper d-flex align-items-center h-100">
        <div className="w-50">
          <div className="progress-circle">
            <CircularProgressbar
              value={approvedPercentage}
              text={`${approvedPercentage} %`}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.25,

                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',

                // Text size
                textSize: '25px',

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,

                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',

                // Colors
                pathColor: '#E59600',
                textColor: '#fff',
                trailColor: '#fff',
                backgroundColor: 'transparent'
              })}
            />
          </div>
        </div>
        <div className="content-wrapper w-50">
          <div className="value-wrapper mb-3">
            <span className="value text-white">{refund.approvedRequest}</span>
            <h5 className="text-white">Approved</h5>
          </div>
          <div className="value-wrapper">
            <span className="value text-white">{refund.pendingRequest}</span>
            <h5 className="text-white">Pending</h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RefundRequests;
