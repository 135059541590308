/**
 * Copies the provided target text to the clipboard , updates UI state variables, and resets them after a 1-second timeout.
 * Passed as props
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */
export const handleCopy = (setCopyClass, setShow, show, target) => {
  navigator.clipboard.writeText(target);
  setCopyClass('copied');
  setShow(!show);
  setTimeout(() => {
    setShow(false);
    setCopyClass('copy');
  }, 1000);
};
