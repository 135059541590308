/**
 * Dynamic refund list,with appropriate actions.
 * Utilizing React Redux for state management.
 * Author: Nauman Sukhera
 * Update 1 (17 Nov, 2023):Integrated filter based search
 * Date: 14 Nov, 2023
 */
import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';

import RefundListObj from './refundListObj';
import Loader from '../common/loader';
import { Toast, config } from '../../config';
import {
  getRefund,
  toastHandler,
  updateRefund,
  dynamicSearch
} from '../../redux/features/refundSlice';

const RefundList = ({ selectedChain, selectedStatus, isFilter, search }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refund = useSelector((state) => state.refund);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(-1);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (
      isFilter === false ||
      (isFilter === true && selectedChain === '' && selectedStatus === '' && search === '')
    ) {
      // empty or filter is not applied
      dispatch(
        getRefund({
          headers: {
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            }
          },
          currentPage: currentPage,
          dispatch: dispatch,
          navigate: navigate
        })
      );
    } else {
      dispatch(
        dynamicSearch({
          headers: {
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            }
          },
          userWalletAddress: search,
          transactionHash: search,
          selectedChain: selectedChain,
          selectedStatus: selectedStatus,
          currentPage: currentPage,
          dispatch: dispatch,
          navigate: navigate
        })
      );
    }
  }, [currentPage, isUpdated, isFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const HandlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setPageOffset(event.selected);
  };
  const handleUpdateRefund = (type, _id, status) => {
    Swal.fire({
      title:
        status === 'pending'
          ? `Do you want to
        ${type === 'approved' ? 'approve' : 'reject'}
         the refund?`
          : 'Action Already Taken!\n Are you sure you want to overwrite the previous action?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `Cancel`
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          updateRefund({
            headers: {
              headers: {
                authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
              }
            },
            payload: { _id: _id, status: type }
          })
        );
      }
    });
  };
  useEffect(() => {
    //track redux states
    if (refund.isError === true) {
      Toast.fire({
        icon: 'error',
        title: refund.error
      });
      dispatch(toastHandler());
    } else if (refund.isUpdated === true) {
      dispatch(toastHandler());
      Toast.fire({
        icon: 'success',
        title: 'Success'
      });
      setIsUpdated(!isUpdated);
    }
  }, [refund]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {refund.isLoading ? (
        <Loader />
      ) : (
        <>
          <Table className="recordes-table users-table mb-0" responsive striped>
            <thead>
              <tr>
                <th>Fee Hash</th>
                <th>User Wallet</th>
                <th>Chain</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {refund.refundData?.map((refund) => {
                return (
                  <tr key={refund._id}>
                    <RefundListObj
                      refund={refund}
                      handleUpdateRefund={handleUpdateRefund}></RefundListObj>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={refund.pageCount}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
        </>
      )}
    </>
  );
};
export default RefundList;
