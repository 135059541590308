/**
 * Logs out the user, resets the application state by redirecting to the home page, and returns "Token Expire!" in case of an invalid token.
 * Argument passed as props
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */
import { logout } from '../redux/features/adminSlice';
export const checkInvalidToken = (err, dispatch, navigate) => {
  dispatch(logout());

  window.location.replace('/'); //to reset other states
  return 'Token Expire!';
};
