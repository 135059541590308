/**
 * Rendering a link that, when clicked, opens the default email client with a pre-filled email to the specified address and subject.
 * Argument passed as props
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */
import React from 'react';
import { Link } from 'react-router-dom';

const ButtonMailTo = ({ email, subject, label }) => {
  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mailtoLink;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export default ButtonMailTo;
