/**
 * It includes routes.
 * ProtectedRoute ensures access control, and Bootstrap CSS is applied for styling.
 * Components are rendered based on specified routes, enabling navigation and authentication.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import LoginForm from './components/login/login';
import Dashboard from './components/dashboard/index';
import ResetPassword from './components/resetPassword/resetPassword';
import TransactionDetails from './components/transactionDetails/index';
import Transactions from './components/transactions';
import Users from './components/users/';
import Roles from './components/roles';
import Refund from './components/refund';
import ContactRequest from './components/contractRequest';
import ProtectedRoute from './utlis/protectedRoutes';
import Setting from './components/setting/setting';
import './App.css';
import Fee from './components/fee/index';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="wrapper">
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/admin/reset-Password/:id/:code" element={<ResetPassword />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transactions"
              element={
                <ProtectedRoute>
                  <Transactions permission={'viewTransaction'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transaction/details/:transactionHash"
              element={
                <ProtectedRoute>
                  <TransactionDetails permission={'viewTransaction'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <Users permission={'viewStaff'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/roles"
              element={
                <ProtectedRoute>
                  <Roles permission={'viewRole'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/refund"
              element={
                <ProtectedRoute>
                  <Refund permission={'viewRefund'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contact-request"
              element={
                <ProtectedRoute>
                  <ContactRequest permission={'viewContact'} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/fee"
              element={
                <ProtectedRoute>
                  <Fee permission={'viewFee'} />
                </ProtectedRoute>
              }
            />
            <Route path="/setting" element={<Setting />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
