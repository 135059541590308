/**
 *Transaction filter component, with search and available chian list.
 * set parent states passed as props
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */
import React from 'react';
import { Container, Dropdown, Row, Col, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import {  useSelector } from 'react-redux/es/hooks/useSelector';

import { config } from '../../config';
import FilterIcon from '../../assets/images/icon-filter.svg';

const UserFilter = ({
  selectedRole,
  setSelectedRole,
  isFilter,
  setIsFilter,
  search,
  setSearch,
  selectedStatus,
  setSelectedStatus
}) => {
  const role = useSelector((state) => state.role);
  const resetHandler = () => {
    setSelectedRole('');
    setIsFilter(false);
    setSelectedStatus('');
    setSearch('');
  };
  const handleEnterKey = (e) => {
    // Code to execute when the Enter key is pressed
    if (e.key === 'Enter') {
      setIsFilter(true);
    }
  };

  return (
    <section className='record-filter' tabIndex={0} onKeyDown={handleEnterKey}>
      <Container fluid className="filters-wrapper mb-3">
        <Row className="justify-content-between gap-lg-0 gap-3">
          <Col lg={5} className="mb-sm-0 mb-2">
            <div className="dropdown-wrapper d-flex gap-3">
              <Dropdown className="w-50">
                <Dropdown.Toggle
                  className="bg-white text-black text-start text-wrap w-100 px-sm-3 px-2 py-2"
                  id="dropdown-basic">
                  {selectedRole ? selectedRole.label : 'All Roles'}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                {role.roleData?.map((role) => (
                    <Dropdown.Item
                      className="text-wrap"
                      key={role.id}
                      value={role.title}
                      onClick={() => {
                        setSelectedRole({id:role._id,label:role.title});
                        setIsFilter(false);
                      }}>
                      {role.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="w-50">
                <Dropdown.Toggle
                  className="bg-white text-black text-start text-wrap w-100 px-sm-3 px-2 py-2"
                  id="dropdown-basic">
                  {selectedStatus ? selectedStatus.label : 'Status'}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {config.userStatus.map((status) => (
                    <Dropdown.Item
                      className="text-wrap"
                      key={status.value}
                      value={status.value}
                      onClick={() => {
                        setSelectedStatus(status);
                        setIsFilter(false);
                      }}>
                      {status.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
          <Col xl={5} lg={7}>
            <div className="d-flex justify-content-end">
              <div className="dropdown-search-box search-box position-relative mb-md-0 mb-2 me-4">
                <input
                  placeholder="Search"
                  className="w-100 h-100"
                  value={search}
                  onChange={(event) => {
                    setIsFilter(false);
                    setSearch(event.target.value);
                  }}
                />
              </div>
              <div className="btn-wrapper d-flex align-items-center">
                {isFilter === false ? (
                  <Button
                    className="gradient-btn h-100 d-flex align-items-center"
                    onClick={() => {
                      setIsFilter(true);
                    }}>
                    Apply
                    <Image className="ms-3" src={FilterIcon} alt="filter icon" />
                  </Button>
                ) : (
                  <Button
                    className="btn-close"
                    onClick={() => {
                      resetHandler();
                    }}>
                    <FontAwesomeIcon icon={faXmark} />
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UserFilter;
