/**
 * Dynamic burn transaction list  .
 * Utilizing React Redux for state management.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux/es/hooks/useSelector';

import BurnListObj from './burnListObj';
import Loader from '../common/loader';

const BurnList = ({ targetTransaction }) => {
  const transaction = useSelector((state) => state.transaction);
  return (
    <>
      {transaction.isLoading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <Table className="recordes-table mb-0" responsive striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>BurnHash</th>
                <th>TokenAddress</th>
                <th>TokenName</th>
                <th>LastUpdated</th>
              </tr>
            </thead>
            <tbody>
              {targetTransaction?.burnTokenTranscations?.map((trnx) => {
                return (
                  <tr key={trnx._id}>
                    <BurnListObj trnx={trnx}></BurnListObj>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};
export default BurnList;
