/**
 * Redux store using the Redux Toolkit, integrating redux-persist for local storage.
 * It combines multiple reducers related to admin, transactions, refunds, contacts, roles, and fees, persisting the admin slice.
 * The store and persistor are then exported for use in the application.
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use local storage as the storage engine

import adminReducer from '../features/adminSlice';
import transactionReducer from '../features/transactionSlice ';
import refundReducer from '../features/refundSlice';
import contactReducer from '../features/constactSlice';
import roleReducer from '../features/rolesSlice';
import feeReducer from '../features/feeSlice';

const persistConfig = {
  key: 'root',
  storage
};

const persistedAdminReducer = persistReducer(persistConfig, adminReducer);

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false // This disables the serializability check
    }),
  reducer: {
    admin: persistedAdminReducer,
    transaction: transactionReducer,
    refund: refundReducer,
    contact: contactReducer,
    role: roleReducer,
    fee: feeReducer
  }
});
export const persistor = persistStore(store);
