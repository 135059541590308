/**
 * Defines a Redux slice for user management in an admin panel, including async thunks for user actions like login, password operations, and user CRUD operations.
 * Handles state changes during async actions and exports the reducer and actions.
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { config } from '../../config';
import { checkInvalidToken } from '../../utlis/checkInvalidToken';

const initialState = {
  logged: false,
  name: '',
  token: '',
  error: '',
  userList: [],
  pageCount: 0,
  isError: false,
  userAdded: false,
  email: '',
  rolePermission: '',
  role: '',
  emailSent: false,
  isUpdated: false,
  isLoading: false
};
export const userLogin = createAsyncThunk(
  'admin/userLogin',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${config.API_URL}/staff/login`, payload.credentials);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const forgetPassword = createAsyncThunk(
  'admin/forgetPassword',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${config.API_URL}/staff/forgot-password`, payload.payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const editPassword = createAsyncThunk(
  'admin/editPassword',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${config.API_URL}/staff/edit-password`,
        payload,
        payload.headers
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        return rejectWithValue(
          checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
        );
      } else {
        if (err.response.status === 401) {
          return rejectWithValue(
            checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
          );
        } else {
          return rejectWithValue(err.response.data.message);
        }
      }
    }
  }
);
export const getUsers = createAsyncThunk('admin/getUsers', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${config.API_URL}/staff/list/?page=${payload.currentPage}&limit=10`,
      payload.headers
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      return rejectWithValue(
        checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
      );
    } else {
      return rejectWithValue(err.response.data.message);
    }
  }
});
export const editUser = createAsyncThunk('admin/editUser', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.put(
      `${config.API_URL}/staff/edit`,
      payload.payload,
      payload.headers
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      return rejectWithValue(
        checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
      );
    } else {
      return rejectWithValue(err.response.data.message);
    }
  }
});

export const dynamicSearch = createAsyncThunk(
  'admin/dynamicSearch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.API_URL}/staff/list/dynamicSearch/?page=${payload.currentPage}&limit=10`,
        {
          roleId: payload?.selectedRole?.id,
          status: payload?.selectedStatus?.value,
          email: payload?.email===""?null:payload.email,
          name: payload?.name===""?null:payload.name,
        },
        payload.headers
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        return rejectWithValue(
          checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
        );
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);
export const deleteUser = createAsyncThunk(
  'admin/deleteUser',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${config.API_URL}/staff/delete/${payload.payload}`,
        payload
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        return rejectWithValue(
          checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
        );
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const addUser = createAsyncThunk('admin/addUser', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${config.API_URL}/staff/create`,
      payload.payload,
      payload.headers
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      return rejectWithValue(
        checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
      );
    } else {
      return rejectWithValue(err.response.data.message);
    }
  }
});
const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState,
  reducers: {
    // existing reducers

    logout: (state) => {
      state.logged = false;
      state.name = '';
      state.token = '';
      state.role = '';
      state.rolePermission = '';
      state.error = '';
      state.email = '';
      state.fullName = '';
      localStorage.clear(); // Remove the token and other stuff from local storage
    },
    toastHandler: (state) => {
      state.error = '';
      state.emailSent = false;
      state.isError = false;
      state.userAdded = false;
      state.isUpdated = false;
    }
  },

  extraReducers: (builder) => {
    //  UserLogin
    builder.addCase(userLogin.pending, (state) => {
      state.updateProfile = false;
      state.isLoading = true;
      state.logged = false;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.logged = true;
      state.name = action.payload.data.fullName;
      state.token = action.payload.data.accessToken;
      state.role = action.payload.adminStatus.title;
      state.rolePermission = action.payload.adminStatus;
      state.error = '';
      state.email = action.payload.data.email;
      state.isLoading = false;
      state.logged = true;
      localStorage.setItem(config.jwtToken, action.payload.data.accessToken);
    });

    builder.addCase(userLogin.rejected, (state, action) => {
      state.updateProfile = false;
      state.error = action.payload;
      state.isLoading = false;
    });
    // forget Password
    builder.addCase(forgetPassword.pending, (state) => {
      state.updateProfile = false;
      state.isLoading = true;
      state.error = '';
      state.emailSent = false;
    });

    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      state.emailSent = true;
      state.isLoading = false;
    });

    builder.addCase(forgetPassword.rejected, (state, action) => {
      state.emailSent = false;
      state.error = action.payload;
      state.isLoading = false;
    });
    // Edit Password
    builder.addCase(editPassword.pending, (state) => {
      state.updateProfile = false;
      state.isLoading = true;
      state.isError = false;
      state.error = '';
    });

    builder.addCase(editPassword.fulfilled, (state, action) => {
      state.updateProfile = true;
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(editPassword.rejected, (state, action) => {
      state.updateProfile = false;
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    });
    // user list
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.userList = [];
      state.error = '';
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.userList = action.payload.data.admins;
      state.pageCount = action.payload.data.pagination.pages;
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(getUsers.rejected, (state, action) => {
      state.updateProfile = false;
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    });
    // user search dynamic
    builder.addCase(dynamicSearch.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.userList = [];
      state.error = '';
    });

    builder.addCase(dynamicSearch.fulfilled, (state, action) => {
      state.userList = action.payload.data.admins;
      state.pageCount = action.payload.data.pagination.pages;
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(dynamicSearch.rejected, (state, action) => {
      state.updateProfile = false;
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    });

    // add user
    builder.addCase(addUser.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.userAdded = false;
      state.error = '';
    });

    builder.addCase(addUser.fulfilled, (state, action) => {
      state.userAdded = true;
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(addUser.rejected, (state, action) => {
      state.userAdded = false;
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    });

    // edit  user
    builder.addCase(editUser.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isUpdated = false;
      state.error = '';
    });

    builder.addCase(editUser.fulfilled, (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(editUser.rejected, (state, action) => {
      state.isUpdated = false;
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    });
    //  Delete Role
    builder.addCase(deleteUser.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isUpdated = false;
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isUpdated = true;
    });

    builder.addCase(deleteUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isUpdated = false;
      state.error = action.payload;
    });
  }
});
export default adminSlice.reducer;
export const { logout, toastHandler } = adminSlice.actions;
