/** Display a form of user to add new user
 * Trigger by index file
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import { Button, Modal, Form } from 'react-bootstrap';
import { useState, useRef } from 'react';

import { Toast, config } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { addUser } from '../../redux/features/adminSlice';
import Loader from '../common/loader';

export const AddUser = ({ modalShow, setModalShow }) => {
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState();
  const nameRef = useRef();
  const roleIdRef = useRef();
  const passwordRef = useRef();
  const emailRef = useRef();
  const statusRef = useRef();
  const confirmPasswordRef = useRef();
  const role = useSelector((state) => state.role);
  const admin = useSelector((state) => state.admin);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleEdit = (event) => {
    event.preventDefault();

    if (
      !nameRef.current.value ||
      !roleIdRef.current.value ||
      !emailRef.current.value ||
      !passwordRef.current.value ||
      !confirmPasswordRef.current.value
    ) {
      Toast.fire({
        icon: 'error',
        title: 'All feilds are required!'
      });
    } else if (confirmPasswordRef.current.value !== passwordRef.current.value) {
      Toast.fire({
        icon: 'error',
        title: 'Password and confirm password should be same!'
      });
    } else {
      const payload = {
        email: emailRef.current.value,
        fullName: nameRef.current.value,
        roleId: roleIdRef.current.value,
        password: passwordRef.current.value,
        status: statusRef.current.checked
      };
      dispatch(
        addUser({
          headers: {
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            }
          },
          payload: payload
        })
      );
    }
  };

  return (
    <>
      {admin.isLoading === true ? (
        <Loader />
      ) : (
        <Modal
          size="md"
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h3 className="text-black fw-medium"> Add User</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-md-5 py-4">
            <Form onSubmit={(event) => handleEdit(event)}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address *</Form.Label>
                <Form.Control type="email" ref={emailRef} placeholder="Enter Email" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Name *</Form.Label>
                <Form.Control type="text" ref={nameRef} placeholder="Name" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password *</Form.Label>
                <Form.Control type="password" ref={passwordRef} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password *</Form.Label>
                <Form.Control type="password" ref={confirmPasswordRef} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Role *</Form.Label>
                <Form.Select onChange={handleRoleChange} value={selectedRole} ref={roleIdRef}>
                  {role.roleData?.map((roleObj) => (
                    <option key={roleObj._id} value={roleObj._id}>
                      {roleObj.title}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Check
                className="custom-switch d-inline-block align-items-center"
                type="switch"
                label="Status "
                ref={statusRef}
                reverse
              />

              <div className="d-flex gap-3 justify-content-center mt-4">
                <Button
                  className="btn-cancel btn-lg"
                  type="button"
                  onClick={() => setModalShow(false)}>
                  Cancel
                </Button>
                <Button className="btn-success btn-lg" type="submit">
                  Create user
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
