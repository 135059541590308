/**
 * Element for changing the user's password, along with user details display.
 * Features:
 *        -> User detail
 *        -> Change passworf
 * It utilizes Redux state for tracking admin errors and update
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React, { useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '../common/sidebar';
import Loader from '../common/loader';
import Banner from '../common/banner';
import { editPassword } from '../../redux/features/adminSlice';
import { Toast, config } from '../../config';
import UserPofile from '../common/userPofile';

const Setting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin = useSelector((state) => state.admin);
  const currentPassword = useRef();
  const newPassword = useRef();
  const confirmPassword = useRef();

  const submit = (event) => {
    event.preventDefault();
    if (
      newPassword.current.value === '' ||
      confirmPassword.current.value === '' ||
      currentPassword.current.value === ''
    ) {
      Toast.fire({
        icon: 'error',
        title: "Password can't be empty! "
      });
    } else if (newPassword.current.value !== confirmPassword.current.value) {
      Toast.fire({
        icon: 'error',
        title: 'Error: Password should be same ! '
      });
    } else {
      SendRequest();
    }
  };
  const SendRequest = () => {
    const payload = {
      new: newPassword.current.value,
      current: currentPassword.current.value,
      headers: {
        headers: {
          authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
        }
      },
      navigate: navigate,
      dispatch: dispatch
    };
    dispatch(editPassword(payload)); //component triggers edit password  via a Redux action
  };

  useEffect(() => {
    const showToast = async () => {
      if (admin.updateProfile === true) {
        Toast.fire({
          icon: 'success',
          title: 'Success!'
        });
        navigate('/dashboard');
      } else if (admin.isError === true) {
        await Toast.fire({
          icon: 'error',
          title: admin.error
        });
      }
    };

    showToast(); // Call the async function
  }, [admin]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="main-wrapper d-flex">
      <Sidebar />
      <div className="content-container">
        <UserPofile />
        <Banner
          heading={'Settings'}
          subheading={'Manage your account'}
          text={
            "Welcome to the Settings page - your hub for adminalizing the application experience. This intuitive interface allows you to tailor the application's features and functionalities to suit your unique needs and preferences. "
          }
        />
        {admin.isLoading ? (
          <Loader />
        ) : (
          <section className="setting sec-bg-blue p-lg-4 p-3">
            <Form className="form-content px-lg-4 px-sm-3" onSubmit={(event) => submit(event)}>
              <Container fluid>
                <Row className="justify-content-between">
                  <Col lg={6} className="pe-5">
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-medium">Name</Form.Label>
                      <Form.Control
                        className="shadow-none"
                        type="text"
                        disabled
                        value={admin.name}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-medium">Email address</Form.Label>
                      <Form.Control
                        className="shadow-none"
                        type="email"
                        disabled
                        value={admin.email}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    {/* <hr /> */}
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-medium">Old Password</Form.Label>
                      <Form.Control
                        className="bg-white shadow-none"
                        type="password"
                        placeholder="Old Password"
                        ref={currentPassword}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-medium">New Password</Form.Label>
                      <Form.Control
                        className="bg-white shadow-none"
                        type="password"
                        placeholder="New Password"
                        ref={newPassword}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-medium">Confirm Password</Form.Label>
                      <Form.Control
                        className="bg-white shadow-none"
                        type="password"
                        placeholder="Confirm Password"
                        ref={confirmPassword}
                      />
                    </Form.Group>
                    <div className="text-end">
                      <Button variant="success" size="lg" type="submit">
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          </section>
        )}
      </div>
    </div>
  );
};

export default Setting;
