/**
 * Transaction page.
 * Features:
 *        -> List of transaction
 *        -> Transaction filter
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React, { useState } from 'react';

import Banner from '../common/banner';
import TransactionFilter from './transactionFilter';
import TransactionList from './transactionList';
import Sidebar from '../common/sidebar';
import UserPofile from '../common/userPofile';
import CopyRights from '../common/copyRights';
import './index.css';

const Transactions = () => {
  const [selectedChain, setSelectedChain] = useState('');
  const [search, setSearch] = useState('');
  const [isFilter, setIsFilter] = useState(false);
  return (
    <>
      <div className="main-wrapper dashboard d-flex">
        <Sidebar />
        <div className="content-container">
          <UserPofile />
          <Banner
            heading={'Transactions'}
            subheading={'View all burn transactions'}
            text={
              'This specialized section offers a comprehensive and chronological record of all token burn events that have occurred within our platform.'
            }
          />
          <div className="record-filter">
            <TransactionFilter
              selectedChain={selectedChain}
              setSelectedChain={setSelectedChain}
              isFilter={isFilter}
              setIsFilter={setIsFilter}
              search={search}
              setSearch={setSearch}
            />

            <TransactionList selectedChain={selectedChain} isFilter={isFilter} search={search} />
          </div>
          <CopyRights />

        </div>
        <></>
      </div>
    </>
  );
};

export default Transactions;
