/**
 * Display a form of Role with permission to add new role
 * Trigger by index file
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import { Button, Modal, Form, Stack } from 'react-bootstrap';
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { addRole } from '../../redux/features/rolesSlice';
import { config, Toast } from '../../config';

export const AddRole = ({ modalShow, setModalShow }) => {
  const titleRef = useRef();
  const dispatch = useDispatch();
  const [checkedPermissions, setCheckedPermissions] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!titleRef.current.value) {
      Toast.fire({
        icon: 'error',
        title: 'Title is Required!'
      });
    } else {
      const payload = {
        title: titleRef.current.value,
        ...checkedPermissions
      };
      dispatch(
        addRole({
          //component triggers add role  via a Redux action
          headers: {
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            }
          },
          payload: payload
        })
      );
    }
  };
  const handlePermissionChange = (permissionId) => {
    setCheckedPermissions((prevCheckedPermissions) => ({
      ...prevCheckedPermissions,
      [permissionId]: !prevCheckedPermissions[permissionId]
    }));
  };
  return (
    <>
      <Modal
        size="md"
        className="roles-model"
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="text-black fw-medium">Add Role</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-md-5 py-3 py-md-4 py-3">
          <Form onSubmit={(event) => handleSubmit(event)} className="">
            <Form.Group className="mb-3">
              <Form.Label className="fw-medium">Title</Form.Label>
              <Form.Control type="text" ref={titleRef} placeholder="Role Title" />
            </Form.Group>
            <Form.Label className="fw-medium">Permissions</Form.Label>
            <div className="d-flex flex-wrap gap-3 border border-1 rounded-2 p-3">
              {config.permissionList &&
                config.permissionList.map((permission) => (
                  <Form.Check
                    key={permission.id}
                    id={permission.id}
                    label={permission.label}
                    className="mb-0"
                    checked={checkedPermissions[permission.id] || false}
                    onChange={() => handlePermissionChange(permission.id)}
                  />
                ))}
            </div>
            <Stack direction="horizontal" gap={3} className="justify-content-center mt-4">
              <Button className="btn-cancel btn-lg" onClick={() => setModalShow(false)}>
                Cancel
              </Button>
              <Button className="btn-success btn-lg" type="submit">
                Create
              </Button>
            </Stack>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
