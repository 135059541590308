/**
 * Sales chart
 * ECharts library to dynamically display a line chart of transaction statistics grouped by months-year.
 * It fetches data using Redux from the backend, processes and sorts the information, and then renders with their transaction amount.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useSelector, useDispatch } from 'react-redux';

import { getTransactionStatsByTime } from '../../redux/features/transactionSlice ';
import { config } from '../../config';

const SalesReport = () => {
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  const [chartData, setChartData] = useState({
    //sample data
    xAxis: {
      type: 'category',
      data: ['2018', '2019', '2020', '2021', '2022', '2023', '2024']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [0, 0, 0, 0, 0, 0, 0],
        type: 'line',
        smooth: true,
        color: ['#E59600']
      }
    ]
  });

  useEffect(() => {
    dispatch(
      getTransactionStatsByTime({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        }
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (transaction.statsByTime.length > 0 && isLoaded === false) {
      const apiData = transaction.statsByTime;
      setIsLoaded(true);

      // Process the API data and update the chartData state
      let feeStats = apiData;

      // Sort the data by year and month in ascending order
      feeStats = [...feeStats].sort((a, b) => {
        let dateA = new Date(a._id.year, a._id.month - 1, 1); // Month is zero-based
        let dateB = new Date(b._id.year, b._id.month - 1, 1);
        return dateA - dateB;
      });

      const xAxisData = feeStats.map((item) => {
        return `${item._id.year}-${item._id.month}`;
      });

      const seriesData = feeStats.map((item) => {
        return item.feeInDollars;
      });

      setChartData({
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          axisLabel: {
            textStyle: { fontSize: 12 }
          },
          name: 'Transaction Amount $',
          type: 'value'
        },
        series: [
          {
            data: seriesData,
            type: 'line',
            smooth: true,
            color: ['#E59600']
          }
        ],
        tooltip: {
          trigger: 'axis', // Trigger tooltip on axis
          formatter: function (params) {
            // Customize the tooltip content
            const pointData = params[0].data; // Assuming only one line series
            return `$: ${pointData}`;
          }
        },
        legend: {
          data: ['Transaction Amount $'], // Legend data, adjust based on your needs
          show: true
        }
      });
    }
  }, [transaction]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <section className="sales-report">
      <h3>Sales Report</h3>

      <ReactEcharts option={chartData} style={{ width: '100%', height: '350px' }}></ReactEcharts>
    </section>
  );
};

export default SalesReport;
