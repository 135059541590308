/**
 * Page for managing contact requests. It includes a sidebar, user profile section, and a list of contact requests.
 * The main content area displays a banner with information about viewing and responding to contact requests.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React, { useEffect, useState } from 'react';

import Banner from '../common/banner';
import ContactRequestList from './contactRequestList';
import Sidebar from '../common/sidebar';
import UserPofile from '../common/userPofile';
import CopyRights from '../common/copyRights';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from '../../config';
import { toastHandler } from '../../redux/features/constactSlice';
const ContactRequest = () => {
  const dispatch = useDispatch();
  const [isUpdated, setIsUpdated] = useState(false); //to keep track of updates
  const contact = useSelector((state) => state.contact);
  useEffect(() => {
    if (contact.isUpdated && contact.updateType === 2) {
      Toast.fire({
        icon: 'success',
        title: 'Marked as closed!'
      });
      dispatch(toastHandler());
      setIsUpdated(!isUpdated);
    }
  }, [contact]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="main-wrapper dashboard d-flex">
        <Sidebar />
        <div className="content-container">
          <UserPofile />
          <Banner
            heading={'Contact Requests'}
            subheading={'View all contact requests'}
            text={
              'Section where you can review and respond to inquiries submitted by users, customers, or potential clients'
            }
          />
          <ContactRequestList setIsUpdated={setIsUpdated} isUpdated={isUpdated} />
          <CopyRights />
        </div>
      </div>
    </>
  );
};

export default ContactRequest;
