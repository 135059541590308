/**
 * Defines a Redux slice for fee, including async thunks  fee view and edit operations.
 * Handles state changes during async actions and exports the reducer and actions.
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */
import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { config } from '../../config';
import { checkInvalidToken } from '../../utlis/checkInvalidToken';

const initialState = {
  primaryFee: 0,
  secondaryFee: 0,
  isError: false,
  isUpdated: false,
  error: '',
  isLoading: false
};

export const getFee = createAsyncThunk('fee/get', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${config.API_URL}/fee/get`, payload.headers);
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      return rejectWithValue(
        checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
      );
    } else {
      return rejectWithValue(err.response.data.message);
    }
  }
});

export const updateFee = createAsyncThunk('fee/updateFee', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${config.API_URL}/fee/update`,
      payload.payload,
      payload.headers
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data.message);
  }
});

const feeSlice = createSlice({
  name: 'Fee',
  initialState: initialState,
  reducers: {
    // existing reducers
    toastHandler: (state) => {
      state.error = '';
      state.isError = false;
      state.isUpdated = false;
      state.isLoading = false;
    }
  },

  extraReducers: (builder) => {
    //  feeData
    builder.addCase(getFee.pending, (state) => {
      state.isLoading = true;
      state.secondaryFee = 0;
      state.primaryFee = 0;
      state.isError = false;
    });

    builder.addCase(getFee.fulfilled, (state, action) => {
      state.isLoading = false;
      state.primaryFee = action.payload.data.fee.primaryFee;
      state.secondaryFee = action.payload.data.fee.secondaryFee;
    });

    builder.addCase(getFee.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    });

    //  Update
    builder.addCase(updateFee.pending, (state) => {
      state.isLoading = true;
      state.isUpdated = false;
    });

    builder.addCase(updateFee.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isUpdated = true;
    });

    builder.addCase(updateFee.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
      state.isUpdated = false;
    });
  }
});
export default feeSlice.reducer;
export const { toastHandler } = feeSlice.actions;
