/**
 * Defines a Redux slice for refund management in an admin panel, including async thunks for refund actions like stats, list, view and action operations.
 * Handles state changes during async actions and exports the reducer and actions.
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */

import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { config } from '../../config';
import { checkInvalidToken } from '../../utlis/checkInvalidToken';

const initialState = {
  pendingRequest: 0,
  approvedRequest: 0,
  refundData: [],
  isError: false,
  isUpdated: false,
  error: '',
  isLoading: false,
  pageCount: 0
};
export const getRefund = createAsyncThunk('refund/get', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${config.API_URL}/refund/list/?page=${payload.currentPage}&limit=10`,
      payload.headers
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      return rejectWithValue(
        checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
      );
    } else {
      return rejectWithValue(err.response.data.message);
    }
  }
});
export const getRefundStats = createAsyncThunk(
  'refund/getRefundStats',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${config.API_URL}/refund/stats`, payload.headers);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateRefund = createAsyncThunk(
  'refund/updateRefund',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.API_URL}/refund/update`,
        payload.payload,
        payload.headers
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);


export const dynamicSearch = createAsyncThunk(
  'refund/dynamicSearch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.API_URL}/refund/list/dynamicSearch/?page=${payload.currentPage}&limit=10`,
        {
          chainID: payload?.selectedChain?.id?.toString(),
          status: payload?.selectedStatus?.value,
          transactionHash:payload?.transactionHash,
          userWalletAddress:payload?.userWalletAddress,
        },
        payload.headers
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        return rejectWithValue(
          checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
        );
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);
const refundSlice = createSlice({
  name: 'Refund',
  initialState: initialState,
  reducers: {
    // existing reducers
    toastHandler: (state) => {
      state.error = '';
      state.isError = false;
      state.isUpdated = false;
      state.isLoading = false;
    }
  },

  extraReducers: (builder) => {
    //  refundData
    builder.addCase(getRefund.pending, (state) => {
      state.isLoading = true;
      state.refundData = [];
      state.isError = false;
    });

    builder.addCase(getRefund.fulfilled, (state, action) => {
      state.isLoading = false;
      state.refundData = action.payload.data.refunds;
      state.pageCount = action.payload.data.pagination.pages;
    });

    builder.addCase(getRefund.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
      state.refundData = [];
    });
      //  list dynamic 
      builder.addCase(dynamicSearch.pending, (state) => {
        state.isLoading = true;
        state.refundData = [];
        state.isError = false;
      });
  
      builder.addCase(dynamicSearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.refundData = action.payload.data.refunds;
        state.pageCount = action.payload.data.pagination.pages;
      });
  
      builder.addCase(dynamicSearch.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isError = true;
        state.refundData = [];
      });
    //  stats
    builder.addCase(getRefundStats.pending, (state) => {
      state.isLoading = true;
      state.pendingRequest = 0;
      state.approvedRequest = 0;
    });

    builder.addCase(getRefundStats.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pendingRequest = action.payload.data.refunds.find((refund) => refund._id === 'pending')
        ? action.payload.data.refunds.find((refund) => refund._id === 'pending').count
        : 0;
      state.approvedRequest = action.payload.data.refunds.find(
        (refund) => refund._id === 'approved'
      )
        ? action.payload.data.refunds.find((refund) => refund._id === 'approved').count
        : 0;
    });

    builder.addCase(getRefundStats.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.pendingRequest = 0;
      state.approvedRequest = 0;
      state.isError = true;
    });
    //  Update
    builder.addCase(updateRefund.pending, (state) => {
      state.isLoading = true;
      state.isUpdated = false;
    });

    builder.addCase(updateRefund.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isUpdated = true;
    });

    builder.addCase(updateRefund.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
      state.isUpdated = false;
    });
  }
});
export default refundSlice.reducer;
export const { toastHandler } = refundSlice.actions;
