import React from "react";
import Lottie from "react-lottie";
import permissionDenied from "../../assets/lotties/permission.json"
import "./index.css"

export const PermissionDenied = () =>{
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: permissionDenied,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    return(
        <section className="permission-denied text-center px-3 py-lg-4 py-3">
            <div className="permission-lottie">
              <Lottie options={defaultOptions} />
            </div>
            <h1 className="mb-lg-4 mb-3">Permission Denied</h1>
        </section>
    )
}