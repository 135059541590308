/**
 * Refund page for managing refund requests.
 * Features a list of refund requests.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 * Update 1 (17 Nov, 2023):Added filter based search
 */
import React, { useState } from 'react';


import Banner from '../common/banner';
import RefundList from './refundList';
import Sidebar from '../common/sidebar';
import UserPofile from '../common/userPofile';
import CopyRights from '../common/copyRights';
import RefundFilter from './refundFilter';


const Refund = () => {
  const [selectedChain, setSelectedChain] = useState("");
  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isFilter, setIsFilter] = useState(false);

  return (
    <>
      <div className="main-wrapper dashboard d-flex">
        <Sidebar />
        <div className="content-container">
          <UserPofile />
          <Banner
            heading={'Refund Requests'}
            subheading={'Manage all refund requests'}
            text={
              'Access all incoming refund requests, complete with status indicators, and  customer information.'
            }
          />
          <RefundFilter
            selectedChain={selectedChain}
            setSelectedChain={setSelectedChain}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            search={search}
            setSearch={setSearch}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
          />
          <RefundList selectedChain={selectedChain} selectedStatus={selectedStatus} isFilter={isFilter} search={search}/>
          <CopyRights />
        </div>
        
      </div>
     
    </>
  );
};

export default Refund;
