/**
 * Display role list item according to props passed ,with delete & edit actions .
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import Swal from 'sweetalert2';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';

import { config } from '../../config';
import { deleteRole } from '../../redux/features/rolesSlice';

const RolesListObj = ({ role, setCurrentEditRole, setModalShow }) => {
  const dispatch = useDispatch();
  const handleDeleteUser = () => {
    Swal.fire({
      title: `Do you want to delete the role ${role?.title} ?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `Cancel`
    }).then((result) => {
      if (result.isConfirmed) {
        //component triggers role deletion via a Redux action
        dispatch(
          deleteRole({
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            },
            payload: role?._id
          })
        );
      }
    });
  };
  return (
    <>
      <td key={role.title}>{role?.title}</td>

      <td align="right">
        <div className="d-inline-flex gap-2">
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit Role</Tooltip>}>
            <Button
              onClick={() => {
                setCurrentEditRole({
                  ...role
                });
                setModalShow(true);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={<Tooltip id="tooltip-top">Delete Role</Tooltip>}
          >
            <Button className="btn-danger" onClick={handleDeleteUser}>
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>
          </OverlayTrigger>
        </div>
      </td>
    </>
  );
};
export default RolesListObj;
