/**
 * File defines a configuration object (config) with various settings, messages, and constants for an application, along with the instantiation of a Swal toast notification object (Toast).
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import Swal from 'sweetalert2';

export const config = {
  emailSentMessage:
    'An email has been sent to your account in case an account with this email exists. Please check your email and follow the instruction in it to reset your password.',
  loginMessage: 'Login successfully!',
  TOAST_TIMMER: process.env.REACT_APP_TOAST_TIMER,
  API_URL:process.env.REACT_APP_API_URL,
  permissionList: [
    { id: 'viewStaff', label: 'View Staff' },
    { id: 'viewDashboard', label: 'View Dashboard' },
    { id: 'addStaff', label: 'Add Staff' },
    { id: 'editStaff', label: 'Edit Staff' },
    { id: 'deleteStaff', label: 'Delete Staff' },
    { id: 'addRole', label: 'Add Role' },
    { id: 'editRole', label: 'Edit Role' },
    { id: 'deleteRole', label: 'Delete Role' },
    { id: 'viewRole', label: 'View Role' },
    { id: 'viewFee', label: 'View Fee' },
    { id: 'updateFee', label: 'Update Fee' },
    { id: 'viewContact', label: 'View Contact Request' },
    { id: 'viewTransaction', label: 'View Transactions' },
    { id: 'viewRefund', label: 'View Refund Requests' },
    { id: 'updateRefund', label: 'Update Refund Request' }
  ],
  jwtToken: process.env.REACT_APP_JWT_TOKEN_ALIS,
  navIcons: [
    {
      id: 1,
      name: 'Dashboard',
      imageName: 'icon-dashboard.svg',
      value: 'dashboard'
    },
    { id: 2, name: 'Users', imageName: 'icon-users.svg', value: 'users' },
    {
      id: 3,
      name: 'Manage Roles',
      imageName: 'icon-manage-roles.svg',
      value: 'roles'
    },
    {
      id: 4,
      name: 'Transaction',
      imageName: 'icon-transaction.svg',
      value: 'transactions'
    },
    {
      id: 5,
      name: 'Refund Request',
      imageName: 'icon-refund-request.svg',
      value: 'refund'
    },
    {
      id: 6,
      name: 'Fee Management',
      imageName: 'icon-fee-management.svg',
      value: 'fee'
    },
    {
      id: 7,
      name: 'Contact Request',
      imageName: 'icon-contact-request.svg',
      value: 'contact-request'
    },

    {
      id: 8,
      name: 'Settings',
      imageName: 'icon-settings.svg',
      value: 'setting'
    }
  ],
  chains: [
    { id: 11155111, label: 'Sepolia' },
    { id: 97, label: 'Binance Smart Chain Testnet' },
    { id: 80001, label: 'Polygon Mumbai' },
    { id: 42161, label: 'Arbitrum' },
    { id: 1, label: 'Ethereum' },
    { id: 137, label: 'Polygon' },
    { id: 43114, label: 'Avalanche' },
    { id: 56, label: 'Binance Smart Chain' },
    { id: 250, label: 'Fantom' }
  ],
  userStatus: [
    { value: true, label: 'Active' },
    { value: false, label: 'InActive' }
  ],
  refundStatus: [
    { value: 'pending', label: 'Pending' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'approved', label: 'Approved' },
  ],
   statusMapping : {
    0: 'Viewed',
    1: 'Pending',
    2: 'Closed'
  }
  
};
export const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: config.TOAST_TIMMER,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});
