/**
 * Defines a Redux slice for contact request in an admin panel, including async thunks contact list,stats,view and edit operations.
 * Handles state changes during async actions and exports the reducer and actions.
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { config } from '../../config';
import { checkInvalidToken } from '../../utlis/checkInvalidToken';
const initialState = {
  pendingRequest: 0,
  openRequest: 0,
  contactData: [],
  error: '',
  isError: false,
  isLoading: false,
  isUpdated: false,
  updateType:0,
  pageCount: 0
};

export const getContactStats = createAsyncThunk(
  'contact/getContactStats',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${config.API_URL}/contact/stats`, payload.headers);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const editContact = createAsyncThunk(
  'contact/editContact',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${config.API_URL}/contact/edit`,
        payload.payload,
        payload.headers
      );
      return {data:response.data,type:payload.payload.status};
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getContactData = createAsyncThunk(
  'contact/getContactData',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/contact/list/?page=${payload.currentPage}&limit=10`,
        payload.headers
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        return rejectWithValue(
          checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
        );
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

const contactSlice = createSlice({
  name: 'Contact',
  initialState: initialState,
  reducers: {
    //  existing reducers
    toastHandler: (state) => {
      state.error = '';
      state.isUpdated = false;
      
    }
  },

  extraReducers: (builder) => {
    //  stats
    builder.addCase(getContactStats.pending, (state) => {
      state.isLoading = true;
      state.pendingRequest = 0;
      state.openRequest = 0;
    });

    builder.addCase(getContactStats.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pendingRequest = action.payload.data.contacts.find((refund) => refund._id === 1)?.count
        ? action.payload.data.contacts.find((refund) => refund._id === 1)?.count
        : 0;
      state.openRequest = action.payload.data.contacts.find((refund) => refund._id === 0)?.count
        ? action.payload.data.contacts.find((refund) => refund._id === 0)?.count
        : 0;
    });

    builder.addCase(getContactStats.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.pendingRequest = 0;
      state.openRequest = 0;
    });
    //  Contact Edit
    builder.addCase(editContact.pending, (state,action) => {
      state.isUpdated = false;
      
    });

    builder.addCase(editContact.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isUpdated = true;
      state.updateType=action.payload.type
    });

    builder.addCase(editContact.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isUpdated = false;
    });
    //  Contact Data
    builder.addCase(getContactData.pending, (state) => {
      state.isLoading = true;
      state.isUpdated = false;
      state.isError = false;
      state.contactData = [];
      state.error = '';
    });

    builder.addCase(getContactData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.contactData = action.payload.data.contact;
      state.pageCount = action.payload.data.pagination.pages;
    });

    builder.addCase(getContactData.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isUpdated = false;
      state.contactData = [];
    });
  }
});
export default contactSlice.reducer;
export const { toastHandler } = contactSlice.actions;
