/**
 * Dynamic sidebar with navigation icons, utilizing React Router and Redux for state management.
 * It features a Lottie animation in the background and a logout button that triggers a Redux action.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useDispatch } from 'react-redux';
import { Image } from 'react-bootstrap';

import { config } from '../../../config';
import WalletIcon from '../../../assets/images/burn-wallet-logo.png';
import LogOutIcon from '../../../assets/images/icon-logout.svg';
import sideBarAnimation from '../../../assets/lotties/sidebar-bg.json';
import { logout } from '../../../redux/features/adminSlice'; // correct slice name
import './sidebar.css';

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
    window.location.replace('/'); //to reset other states
  };

  const [activeIcon, setActiveIcon] = useState(); //for active state navIcons[0].id
  const handleIconClick = (icon) => {
    setActiveIcon(icon.id);
    navigate(`/${icon.value}`);
  };
  const handleIconMouseEnter = (iconId) => {
    setActiveIcon(iconId);
  };

  const handleIconMouseLeave = () => {
    setActiveIcon(null);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    speed: 10,
    animationData: sideBarAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <aside className="side-bar position-fixed">
      <div className="menu-wrapper text-center">
        <div className="logo-icon d-flex align-items-center justify-content-center">
          <Image src={WalletIcon} alt="Logo icon" width="44" fluid />
        </div>
        <ul className="menu-list list-unstyled text-center py-lg-5 py-4 mb-0">
          {config.navIcons.map((icon) => (
            <li
              key={icon.id}
              className={`position-relative ${activeIcon === icon.id ? 'active' : ''}`}
              role="button"
              onClick={() => handleIconClick(icon)}
              onMouseEnter={() => handleIconMouseEnter(icon.id)}
              onMouseLeave={handleIconMouseLeave}>
              <Image
                src={require(`../../../assets/images/${icon.imageName}`)}
                alt={`${icon.name} icon`}
                width={20}
                fluid
              />
              <span className="d-sm-inline-flex d-none align-items-center text-start">
                {icon.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="sidebar-lottie">
        <Lottie options={defaultOptions} />
      </div>
      <button className="logout-btn d-flex align-items-center" onClick={logoutHandler}>
        <Image src={LogOutIcon} alt="icon" fluid />
      </button>
    </aside>
  );
};

export default Sidebar;
