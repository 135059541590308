/**
 * Display transaction list item according to props passed with view details action.
 * View transaction navigate to detail transaction passing transaction hash as params.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';

import { handleCopy } from '../../utlis/handleCopy';

const TransactionListObj = ({ trnx }) => {
  const navigate = useNavigate();
  // state for copy class icon
  const [copyClassHash, setCopyClassHash] = useState('copy');
  const [copyClassWallet, setCopyClassWallet] = useState('copy');

  //  tooltip
  const [show, setShow] = useState(false);
  const [showWalletToottip, setWalletToottip] = useState(false);
  const targetWallet = useRef(null);
  const targetHash = useRef(null);

  return (
    <>
      <td>
        <div className="d-inline-flex">
          {trnx?.transactionHash}
          <FontAwesomeIcon
            className={`ms-3 ${copyClassHash}`}
            icon={faCopy}
            role="button"
            ref={targetHash}
            onClick={() => {
              handleCopy(setCopyClassHash, setShow, show, trnx?.transactionHash);
            }}
          />
          <Overlay target={targetHash.current} show={show} placement="top">
            {(trnx) => <Tooltip {...trnx}>Copied</Tooltip>}
          </Overlay>
        </div>
      </td>

      <td>
        <div className="d-inline-flex">
          {trnx?.userWalletAddress}
          <FontAwesomeIcon
            className={`ms-3 ${copyClassWallet}`}
            icon={faCopy}
            role="button"
            ref={targetWallet}
            onClick={() => {
              handleCopy(
                setCopyClassWallet,
                setWalletToottip,
                showWalletToottip,
                trnx?.userWalletAddress
              );
            }}
          />
          <Overlay target={targetWallet.current} show={showWalletToottip} placement="top">
            {(trnx) => <Tooltip {...trnx}>Copied</Tooltip>}
          </Overlay>
        </div>
      </td>
      <td>{trnx?.chainID}</td>
      <td>{trnx?.chainName}</td>
      <td>${trnx?.feeInDollars}</td>
      <td>
        <Button
          className="gradient-btn"
          onClick={() => {
            navigate(`/transaction/details/${trnx.transactionHash}`);
          }}
        >
          View Details
        </Button>
      </td>
    </>
  );
};
export default TransactionListObj;
