/**
 * Login Page
 * Handle login and forget password
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import React, { useEffect, useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import BurnWallet from '../../assets/images/burn-wallet-logo.png';
import { Toast } from '../../config';
import Loader from '../common/loader';
import { userLogin, forgetPassword, toastHandler } from '../../redux/features/adminSlice';
import { config } from '../../config';
import './login.css';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [emailError, setEmailError] = useState();
  const [eyeIcon, setEyeIcon] = useState(faEye);

  const admin = useSelector((state) => state.admin);

  const dipatchRequest = () => {
    setIsLoading(true);
    const payload = { email: email, password: password };
    dispatch(userLogin({ credentials: payload }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      Toast.fire({
        icon: 'error',
        title: 'Invalid Email!'
      });
    } else {
      dipatchRequest();
    }
  };

  const toggleEye = () => {
    // Eye icon
    eyeIcon === faEye ? setEyeIcon(faEyeSlash) : setEyeIcon(faEye);
  };

  const forgetPasswordHandler = async () => {
    const { value: userEmailModal } = await Swal.fire({
      title: 'Forgotten Password?',
      input: 'email',
      inputPlaceholder: 'Enter your email address'
    });

    if (userEmailModal) {
      const payload = { email: userEmailModal };
      dispatch(forgetPassword({ payload: payload }));
    }
  };

  useEffect(() => {
    //tracking  redux admin states
    if (admin.logged === true) {
      Toast.fire({
        icon: 'success',
        title: config.loginMessage
      });
      dispatch(toastHandler());
      navigate('/dashboard');
    } else if (admin.error !== '') {
      setIsLoading(false);
      Toast.fire({
        icon: 'error',
        title: admin.error
      });
      dispatch(toastHandler());
    } else if (admin.emailSent === true) {
      setIsLoading(false);
      Toast.fire({
        icon: 'success',
        title: config.emailSentMessage
      });
      dispatch(toastHandler());
    }
  }, [admin]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="login-form-area">
      <div className="login-form-main position-relative">
        <div className="wallet-logo-wrapper d-md-block d-none">
          <Image src={BurnWallet} alt="wallet logo" width="350" fluid />
        </div>
        <div className="login-manage-wrapper">
          <div className="login-form">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <div className="login-form-content">
                  <h4>SIGN IN</h4>
                  <p className="mb-3">Unlock your world with a single tap</p>
                  <Form className="form-content" onSubmit={(event) => handleSubmit(event)}>
                    <div className="form-group-wrapper">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Your Email"
                          onChange={(event) => {
                            if (
                              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(event.target.value)
                            ) {
                              setEmailError(true);
                            } else {
                              setEmailError(false);
                            }
                            setEmail(event.target.value);
                          }}
                        />
                        {emailError ? (
                          <p className="error-text">Please enter a valid email address.</p>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-3 password-feild position-relative"
                        controlId="formBasicPassword"
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type={eyeIcon === faEye ? 'password' : 'text'}
                          placeholder="Enter your password"
                        />
                        <FontAwesomeIcon
                          className="password-eye-icon"
                          role="button"
                          icon={eyeIcon}
                          onClick={toggleEye}
                        />
                      </Form.Group>
                    </div>
                    <div className="remember-login mb-md-4 mb-3 d-sm-flex align-items-center justify-content-between text-sm-start text-end">
                      <Button className="btn-blue" type="submit" variant="none">
                        Login
                      </Button>
                    </div>
                    <Link
                      to="/"
                      className="forgot-link"
                      onClick={() => {
                        forgetPasswordHandler();
                      }}
                    >
                      Forgotten password?
                    </Link>
                  </Form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
