/**
 * Reset password page redirected from user mail
 * Handle reset  password API with axios.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React from 'react';
import { Form, Button, Container, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './resetPassword.css';
import { config, Toast } from '../../config';
import Lottie from 'react-lottie';
import resetPassword from '../../assets/lotties/reset-password.json';

const ResetPassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState();
  const [confrimPassword, setConfrimPassword] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (confrimPassword === password) {
      axios
        .put(`${config.API_URL}/staff/reset-password`, {
          _id: params.id,
          code: params.code,
          new: password
        })
        .then((res) => {
          Toast.fire({
            icon: 'success',
            title: res.data.message
          });
          navigate('/');
        })
        .catch((err) => {
          Toast.fire({
            icon: 'error',
            title: err.response.data.message
          });
        });
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Password must be same!'
      });
    }
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: resetPassword,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <section className="reset-password position-relative px-3 py-5 d-flex justify-content-center w-100 flex-column">
      <Container>
        <Row className="flex-lg-row-reverse align-items-center justify-content-between">
          <Col lg={6}>
            <div className="reset-password-container position-relative rounded-4 w-100 p-md-5 p-4 mb-lg-0 mb-5">
              <Form onSubmit={(event) => handleSubmit(event)}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                  <Form.Text className="text-muted">
                    {`We'll never share your details with anyone else.`}
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(event) => {
                      setConfrimPassword(event.target.value);
                    }}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </Col>
          <Col lg={5}>
            <div className="password-lottie">
              <Lottie options={defaultOptions} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ResetPassword;
