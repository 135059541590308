/**
 * Display burn transaction list item according to trnx passed
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Overlay, Tooltip } from 'react-bootstrap';
import { useState, useRef } from 'react';

import { handleCopy } from '../../utlis/handleCopy';

const BurnListObj = ({ trnx }) => {
  //copy class
  const [copyClassHash, setCopyClassHash] = useState('copy');
  const [copyClassToken, setCopyClassToken] = useState('copy');
  //tooltip
  const [showHashToottip, setWalletToottip] = useState(false);
  const [showTokenTooltip, setTokenTooltip] = useState(false);
  const targetWallet = useRef(null);
  const targetToken = useRef(null);

  return (
    <>
      <td>{trnx._id}</td>
      <td>
        {trnx.burnTransactionHash === '' ? (
          '...'
        ) : (
          <>
            {trnx.burnTransactionHash}
            <FontAwesomeIcon
              className={`ms-3 ${copyClassHash}`}
              icon={faCopy}
              role="button"
              ref={targetWallet}
              onClick={() => {
                handleCopy(
                  setCopyClassHash,
                  setWalletToottip,
                  showHashToottip,
                  trnx.burnTransactionHash
                );
              }}
            />
            <Overlay target={targetWallet.current} show={showHashToottip} placement="top">
              {(trnx) => <Tooltip {...trnx}>Copied</Tooltip>}
            </Overlay>
          </>
        )}
      </td>
      <td>
        {trnx.tokenAddress}
        <FontAwesomeIcon
          className={`ms-3 ${copyClassToken}`}
          icon={faCopy}
          role="button"
          ref={targetToken}
          onClick={() => {
            handleCopy(setCopyClassToken, setTokenTooltip, showTokenTooltip, trnx.tokenAddress);
          }}
        />
        <Overlay target={targetToken.current} show={showTokenTooltip} placement="top">
          {(trnx) => <Tooltip {...trnx}>Copied</Tooltip>}
        </Overlay>
      </td>
      <td>{trnx.tokenName}</td>
      <td>{new Date(trnx?.updatedAt).toLocaleString()}</td>
    </>
  );
};
export default BurnListObj;
