/** ProtectedRoute component that checks user authentication and permissions using Redux state.
 *It redirects to the login page if not authenticated or lacking required permissions, and renders its child components only for authenticated users with proper permissions.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Toast, config } from '../config';

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const admin = useSelector((state) => state.admin);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = async () => {
    const userToken = localStorage.getItem(config.jwtToken);

    const permissionRole = admin.rolePermission;

    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
      return window.location.replace('/'); //to reset other presistant redux states
    } else if (permissionRole[props.children.props.permission] === false) {
      Toast.fire({
        icon: 'error',
        title: 'Not Allowed!'
      });
      return navigate('/dashboard');
    }
    setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};
export default ProtectedRoute;
