/**
 * Contact Request
 * Display contact stats chart in percentage for opened request against total request.
 * It utilizes Redux state for stats data.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getContactStats } from '../../redux/features/constactSlice';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../config';

const ContactRequests = () => {
  const dispatch = useDispatch();
  const contact = useSelector((state) => state.contact);
  const [openPercentage, setOpenPercentage] = useState(0);
  useEffect(() => {
    dispatch(
      getContactStats({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        }
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setOpenPercentage(
      (contact?.openRequest / (contact?.openRequest + contact?.pendingRequest)).toFixed(1) * 100
    );
  }, [contact]);
  return (
    <section className="contact-requests">
      <h3 className="text-white mb-lg-5 mb-4">Contact Requests</h3>
      <div className="progress-wrapper d-flex align-items-center">
        <div className="w-50">
          <div className="progress-circle">
            <CircularProgressbar
              value={openPercentage}
              text={openPercentage + '%'}
              // text={`${percentage}%`}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.25,

                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',

                // Text size
                textSize: '25px',

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,

                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',

                // Colors
                pathColor: '#5C8E00',
                textColor: '#fff',
                trailColor: '#fff',
                backgroundColor: 'transparent'
              })}
            />
          </div>
        </div>
        <div className="content-wrapper ms-xxl-5 ms-sm-4 ms-3 w-50">
          <div className="value-wrapper mb-3">
            <span className="value text-white">{contact.openRequest + contact.pendingRequest}</span>
            <h5 className="text-white">Received</h5>
          </div>
          <div className="value-wrapper">
            <span className="value text-white">{contact.openRequest}</span>
            <h5 className="text-white">Opened</h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactRequests;
