/**
 * Defines a Redux slice for role management in an admin panel, including async thunks for role CRUD operations.
 * Handles state changes during async actions and exports the reducer and actions.
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */

import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { config } from '../../config';
import { checkInvalidToken } from '../../utlis/checkInvalidToken';

const initialState = {
  roleData: [],
  error: '',
  isLoading: false,
  isError: false,
  pageCount: 0,
  isUpdated: false
};

export const getRoles = createAsyncThunk('role/get', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${config.API_URL}/role/list/?page=${payload.currentPage}&limit=10`,
      payload.headers
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      return rejectWithValue(
        checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
      );
    } else {
      return rejectWithValue(err.response.data.message);
    }
  }
});
export const getAllRoles = createAsyncThunk('role/getAll', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${config.API_URL}/role/list/?all=true`, payload.headers);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data.message);
  }
});
export const editRole = createAsyncThunk('role/editRole', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.put(
      `${config.API_URL}/role/edit`,
      payload.payload,
      payload.headers
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data?.message);
  }
});
export const addRole = createAsyncThunk('role/addRole', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${config.API_URL}/role/create`,
      payload.payload,
      payload.headers
    );
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data?.message);
  }
});
export const deleteRole = createAsyncThunk(
  'role/deleteRole',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${config.API_URL}/role/delete?_id=${payload.payload}`,
        payload
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data?.message);
    }
  }
);

const roleSlice = createSlice({
  name: 'role',
  initialState: initialState,
  reducers: {
    //  existing reducers
    toastHandler: (state) => {
      state.error = '';
      state.isError = false;
      state.isUpdated = false;
    }
  },

  extraReducers: (builder) => {
    //  roleData
    builder.addCase(getRoles.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.roleData = [];
      state.pageCount = 0;
    });

    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.roleData = action.payload.data;
      state.pageCount = action.payload.pages;
    });

    builder.addCase(getRoles.rejected, (state, action) => {
      state.error = action.payload;
      state.isError = true;
      state.isLoading = false;
      state.roleData = [];
      state.pageCount = 0;
    });
    //  AllroleData
    builder.addCase(getAllRoles.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.roleData = [];
    });

    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.roleData = action.payload.data;
    });

    builder.addCase(getAllRoles.rejected, (state, action) => {
      state.error = action.payload;
      state.isError = true;
      state.isLoading = false;
      state.roleData = [];
    });
    //  Addrole
    builder.addCase(addRole.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isUpdated = false;
    });

    builder.addCase(addRole.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isUpdated = true;
    });

    builder.addCase(addRole.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isUpdated = false;
      state.error = action.payload;
    });
    //  Editrole
    builder.addCase(editRole.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isUpdated = false;
    });

    builder.addCase(editRole.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isUpdated = true;
    });

    builder.addCase(editRole.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isUpdated = false;
      state.error = action.payload;
    });
    //  Delete Role
    builder.addCase(deleteRole.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isUpdated = false;
    });

    builder.addCase(deleteRole.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isUpdated = true;
    });

    builder.addCase(deleteRole.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isUpdated = false;
      state.error = action.payload;
    });
  }
});
export default roleSlice.reducer;
export const { toastHandler } = roleSlice.actions;
