/**
 * Defines a Redux slice for transaction in an admin panel, including async thunks for trasnsaction stats,list and view operations.
 * Handles state changes during async actions and exports the reducer and actions.
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 * Update 1 (20 Nov, 2023):Integrated dynamic search api 

 */

import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { config } from '../../config';
import { checkInvalidToken } from '../../utlis/checkInvalidToken';

const initialState = {
  transactionData: [],
  error: '',
  isError: false,
  isLoading: false,
  totalTransactionCount: 0,
  totalTransactionAmount: 0,
  statsByCountry: [],
  statsByTime: [],
  pageCount: 0
};

export const getTransaction = createAsyncThunk(
  'transaction/get',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/transaction/list/?page=${payload.currentPage}&limit=10`,
        payload.headers
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        return rejectWithValue(
          checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
        );
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const dynamicSearch = createAsyncThunk(
  //search Chain, wallet or transaction Hash
  'transaction/dynamicSearch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.API_URL}/transaction/list/dynamicSearch/?page=${payload.currentPage}&limit=10`,
        {
          chainID: payload?.selectedChain?.id?.toString(),
          userWalletAddress: payload?.userWalletAddress,
          transactionHash: payload?.transactionHash
        },
        payload.headers
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        return rejectWithValue(
          checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
        );
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);

export const getTransactionStats = createAsyncThunk(
  'transaction/getTransactionStats',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${config.API_URL}/transaction/stats`, payload.headers);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getTransactionStatsByCountry = createAsyncThunk(
  'transaction/getTransactionStatsByCountry',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/transaction/statsByCountry`,
        payload.headers
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        return rejectWithValue(
          checkInvalidToken(err.response.status, payload.dispatch, payload.navigate)
        );
      } else {
        return rejectWithValue(err.response.data.message);
      }
    }
  }
);
export const getTransactionStatsByTime = createAsyncThunk(
  'transaction/getTransactionStatsByTime',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/transaction/statsByTime`,
        payload.headers
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: initialState,
  reducers: {
    //  existing reducers
    toastHandler: (state) => {
      state.error = '';
      state.emailSent = '';
      state.isError = false;
    }
  },

  extraReducers: (builder) => {
    //  transactionData list
    builder.addCase(getTransaction.pending, (state) => {
      state.isLoading = true;
      state.logged = false;
      state.transactionData = [];
    });

    builder.addCase(getTransaction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionData = action.payload.data.transactions;
      state.pageCount = action.payload.data.pagination.pages;
    });

    builder.addCase(getTransaction.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.transactionData = [];
    });
    //  transactionData by Chain / wallet /trasnactionHash
    builder.addCase(dynamicSearch.pending, (state) => {
      state.isLoading = true;
      state.logged = false;
      state.transactionData = [];
    });

    builder.addCase(dynamicSearch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionData = action.payload.data.transactions;
      state.pageCount = action.payload.data.pagination.pages;
    });

    builder.addCase(dynamicSearch.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.transactionData = [];
    });
   

    //  Stats  collection
    builder.addCase(getTransactionStats.pending, (state) => {
      state.isLoading = true;
      state.totalTransactionCount = 0;
      state.totalTransactionAmount = 0;
    });

    builder.addCase(getTransactionStats.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalTransactionCount = action.payload.data.count;
      state.totalTransactionAmount = action.payload.data.feeInDollars;
    });

    builder.addCase(getTransactionStats.rejected, (state, action) => {
      state.error = action.payload;
      state.totalTransactionCount = 0;
      state.totalTransactionAmount = 0;
    });
    //  Stats Country collection
    builder.addCase(getTransactionStatsByCountry.pending, (state) => {
      state.isLoading = true;
      state.statsByCountry = [];
      state.isError = false;
    });

    builder.addCase(getTransactionStatsByCountry.fulfilled, (state, action) => {
      state.isLoading = false;
      state.statsByCountry = action.payload.data.feeStats;
    });

    builder.addCase(getTransactionStatsByCountry.rejected, (state, action) => {
      state.error = action.payload;
      state.isError = true;
    });
    //  Stats Time collection
    builder.addCase(getTransactionStatsByTime.pending, (state) => {
      state.isLoading = true;
      state.statsByTime = [];
      state.isError = false;
    });

    builder.addCase(getTransactionStatsByTime.fulfilled, (state, action) => {
      state.isLoading = false;
      state.statsByTime = action.payload.data.feeStats;
    });

    builder.addCase(getTransactionStatsByTime.rejected, (state, action) => {
      state.error = action.payload;
      state.isError = true;
    });
  }
});
export default transactionSlice.reducer;
export const { toastHandler } = transactionSlice.actions;
