/**
 * Dynamic contact list, utilizing React Router and Redux for state management.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ContactRequestListObj from './contactRequestListObj';
import { DetailContactRequest } from './detailContactRequest';
import Loader from '../common/loader';
import { getContactData } from '../../redux/features/constactSlice';
import { config } from '../../config';

const ContactRequestList = ({ setIsUpdated, isUpdated }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contact = useSelector((state) => state.contact);
  const [modalShow, setModalShow] = useState(false);
  const [currentDetailContact, setCurrentDetailContact] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(-1);

  useEffect(() => {
    dispatch(
      //dispatch to get contact data and refetch once update dependecy change
      getContactData({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        },
        currentPage: currentPage,
        dispatch: dispatch,
        navigate: navigate
      })
    );
  }, [currentPage, isUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const HandlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setPageOffset(event.selected);
  };

  return (
    <>
      {contact.isLoading ? (
        <Loader />
      ) : (
        <>
          <Table className="recordes-table users-table mb-0" responsive striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {contact.contactData?.map((contactObj) => {
                return (
                  <tr key={contactObj._id}>
                    <ContactRequestListObj
                      contactObj={contactObj}
                      setModalShow={setModalShow}
                      setCurrentDetailContact={setCurrentDetailContact}
                    ></ContactRequestListObj>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={contact.pageCount}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
          <DetailContactRequest
            modalShow={modalShow}
            setModalShow={setModalShow}
            currentDetailContact={currentDetailContact}
            setIsUpdated={setIsUpdated}
          />
        </>
      )}
    </>
  );
};
export default ContactRequestList;
