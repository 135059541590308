/**
 * Fee form shows default set fee and updates according to set values.
 * Additionally, the component utilizes Redux for state management.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Loader from '../common/loader';
import { getFee, updateFee } from '../../redux/features/feeSlice';
import { config } from '../../config';

const FeeForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const feeRedux = useSelector((state) => state.fee);
  const [primaryFee, setPrimaryFee] = useState();
  const [secondaryFee, setSecondaryFee] = useState();

  useEffect(() => {
    dispatch(
      getFee({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        },
        dispatch: dispatch,
        navigate: navigate
      })
    );
  }, [feeRedux.isUpdated, feeRedux.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateFee({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        },
        payload: {
          primaryFee: primaryFee ? primaryFee : feeRedux.primaryFee,
          secondaryFee: secondaryFee ? secondaryFee : feeRedux.secondaryFee
        }
      })
    );
  };

  return (
    <section className="fee-sec sec-bg-blue p-lg-4 p-3 col-4">
      {feeRedux.isLoading ? (
        <Loader />
      ) : (
        <>
          <Form className="form-content px-lg-2 px-sm-4" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="fw-medium">Primary Fee</Form.Label>
              <Form.Control
                className="bg-white shadow-none"
                type="text"
                placeholder="Primary Fee"
                defaultValue={feeRedux.primaryFee}
                onChange={(e) => setPrimaryFee(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="fw-medium">Secondary Fee</Form.Label>
              <Form.Control
                className="bg-white shadow-none"
                type="text"
                placeholder="Secondary Fee"
                defaultValue={feeRedux.secondaryFee}
                onChange={(e) => setSecondaryFee(e.target.value)}
              />
            </Form.Group>
            <div className="text-end">
              <Button variant="success" size="lg" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </>
      )}
    </section>
  );
};

export default FeeForm;
