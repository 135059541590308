/**
 * Banner display heading subheadin and text according to passed prosps
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Banner = ({ heading, subheading, text }) => {
  return (
    <section className="general-banner position-relative">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={5}>
            <div className="heading-wrapper position-relative py-lg-4 me-xl-4 mb-lg-0 mb-4">
              <h2 className="mb-2">{heading}</h2>
              <p className="p-lg text-white">{subheading}</p>
            </div>
          </Col>
          <Col lg={7}>
            <div className="py-lg-4">
              <p className="p-lg text-white">{text}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
