/**
 * Loader.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React from 'react';
import { Spinner } from 'react-bootstrap';

import './loader.css';

const Loader = () => {
  return (
    <div className="loader-container">
      <Spinner animation="border" role="status">
        <span className="sr-only"></span>
      </Spinner>
    </div>
  );
};

export default Loader;
