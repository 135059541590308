/**
 * Banner
 * Display stats such as transaction and refund.
 * It utilizes Redux state for stats data.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import React, { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PendingRequestIcon from '../../assets/images/icon-pending-request.svg';
import ApprovedRequestIcon from '../../assets/images/icon-approved-signal.svg';
import TransactionIcon from '../../assets/images/icon-transaction-filled.svg';
import DollarIcon from '../../assets/images/icon-dollar.svg';
import { getTransactionStats } from '../../redux/features/transactionSlice ';
import { getRefundStats } from '../../redux/features/refundSlice';
import { config } from '../../config';

const Banner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const transaction = useSelector((state) => state.transaction);
  const refund = useSelector((state) => state.refund);
  const admin = useSelector((state) => state.admin);


  useEffect(() => {
    dispatch(
      getTransactionStats({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        }
      })
    );
    dispatch(
      getRefundStats({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        }
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <section className="dashboard-banner position-relative">
      <h2 className="mb-lg-2 mb-sm-4 mb-3">Welcome to Burn Wallet</h2>
      <Container fluid className="cards-wrapper p-0">
        <Row>
          <Col xl={3} md={6} className="mb-xl-0 mb-3">
            <div
              className="info-card d-flex align-items-center gap-xxl-4 gap-3"
              role="button"
              onClick={() => {
                navigate('/refund');
              }}
            >
              <div className="icon-wrapper yellow d-flex align-items-center justify-content-center">
                <Image src={PendingRequestIcon} alt="icon" fluid />
              </div>
              <div className="content">
                <h5 className="mb-sm-2 mb-1">Pending Request</h5>
                <span className="value yellow">{admin.rolePermission.viewRefund===true?refund.pendingRequest:<span className='not-allowed'>Not Allowed</span>}</span>
              </div>
            </div>
          </Col>
          <Col xl={3} md={6} className="mb-xl-0 mb-3">
            <div
              className="info-card d-flex align-items-center gap-xxl-4 gap-3"
              role="button"
              onClick={() => {
                navigate('/refund');
              }}
            >
              <div className="icon-wrapper green d-flex align-items-center justify-content-center">
                <Image src={ApprovedRequestIcon} alt="icon" fluid />
              </div>
              <div className="content">
                <h5 className="mb-sm-2 mb-1">Approved Request</h5>
                <span className="value green">{admin.rolePermission.viewRefund===true?refund.approvedRequest:<span className='not-allowed'>Not Allowed</span>}</span>
              </div>
            </div>
          </Col>
          <Col xl={3} md={6} className="mb-xl-0 mb-3">
            <div
              className="info-card d-flex align-items-center gap-xxl-4 gap-3"
              role="button"
              onClick={() => {
                navigate('/transactions');
              }}
            >
              <div className="icon-wrapper blue d-flex align-items-center justify-content-center">
                <Image src={TransactionIcon} alt="icon" fluid />
              </div>
              <div className="content">
                <h5 className="mb-sm-2 mb-1">Total Transactions</h5>
                <span className="value blue">{admin.rolePermission.viewTransaction===true?transaction.totalTransactionCount:<span className='not-allowed'>Not Allowed</span>}</span>
              </div>
            </div>
          </Col>
          <Col xl={3} md={6} className="mb-xl-0 mb-3">
            <div
              className="info-card d-flex align-items-center gap-xxl-4 gap-3"
              role="button"
              onClick={() => {
                navigate('/transactions');
              }}
            >
              <div className="icon-wrapper red d-flex align-items-center justify-content-center">
                <Image src={DollarIcon} alt="icon" fluid />
              </div>
              <div className="content">
                <h5 className="mb-sm-2 mb-1">Transaction Amount</h5>
                <span className="value red">{admin.rolePermission.viewTransaction===true?`$${transaction.totalTransactionAmount}`:<span className='not-allowed'>Not Allowed</span>}</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
