/** Parent Transaction page.
 * shows tranasaction passed as props
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Overlay, Tooltip } from 'react-bootstrap';
import React, { useState, useRef } from 'react';

import { handleCopy } from '../../utlis/handleCopy';

const ParentTransaction = ({ targetTransaction }) => {
  const [copyClassHash, setCopyClassHash] = useState('copy');
  const [copyClassWallet, setCopyClassWallet] = useState('copy');

  const [show, setShow] = useState(false);
  const [showWalletToottip, setWalletToottip] = useState(false);
  const targetWallet = useRef(null);
  const targetHash = useRef(null);
  return (
    <div className="record-filter">
      <div className="filters-wrapper">
        <div className="transection-details d-flex align-items-center flex-wrap">
          <div className="info-wrapper d-inline-flex align-items-center">
            <h5 className="text-black opacity-100 me-sm-3 me-2 mb-0">Fee Transaction Hash</h5>
            <span className="text-white fw-medium value text-center d-inline-block">
              {targetTransaction?.transactionHash}{' '}
              <FontAwesomeIcon
                className={`ms-3 ${copyClassHash} text-white`}
                icon={faCopy}
                role="button"
                ref={targetHash}
                onClick={() => {
                  handleCopy(setCopyClassHash, setShow, show, targetTransaction?.transactionHash);
                }}
              />
              <Overlay target={targetHash.current} show={show} placement="top">
                {(props) => <Tooltip {...props}>Copied</Tooltip>}
              </Overlay>
            </span>
          </div>
          <div className="info-wrapper d-inline-flex align-items-center">
            <h5 className="text-black opacity-100 me-sm-3 me-2 mb-0">User Wallet Address</h5>
            <span className="text-white fw-medium value text-center d-inline-block">
              {targetTransaction?.userWalletAddress}
              <FontAwesomeIcon
                className={`ms-3 ${copyClassWallet} text-white`}
                icon={faCopy}
                role="button"
                ref={targetWallet}
                onClick={() => {
                  handleCopy(
                    setCopyClassWallet,
                    setWalletToottip,
                    showWalletToottip,
                    targetTransaction?.userWalletAddress
                  );
                }}
              />
              <Overlay target={targetWallet.current} show={showWalletToottip} placement="top">
                {(props) => <Tooltip {...props}>Copied</Tooltip>}
              </Overlay>
            </span>
          </div>
          <div className="info-wrapper d-inline-flex align-items-center">
            <h5 className="text-black opacity-100 me-sm-3 me-2 mb-0">Last Updated</h5>
            <span className="text-white fw-medium value text-center d-inline-block">
              {new Date(targetTransaction?.updatedAt).toLocaleString()}
            </span>
          </div>
          <div className="info-wrapper d-inline-flex align-items-center">
            <h5 className="text-black opacity-100 me-sm-3 me-2 mb-0">
              Total Fee in Native Currency
            </h5>
            <span className="text-white fw-medium value text-center d-inline-block">
              {targetTransaction?.feeInNative}
              {targetTransaction?.chainName}
            </span>
          </div>
          <div className="info-wrapper d-inline-flex align-items-center">
            <h5 className="text-black opacity-100 me-sm-3 me-2 mb-0">Total Fee Paid</h5>
            <span className="text-white fw-medium value text-center d-inline-block">
              {targetTransaction?.feeInDollars}$
            </span>
          </div>
          <div className="info-wrapper d-inline-flex align-items-center">
            <h5 className="text-black opacity-100 me-sm-3 me-2 mb-0">Token Count</h5>
            <span className="text-white fw-medium value text-center d-inline-block">
              {targetTransaction?.burnTokenTranscations?.length}
            </span>
          </div>
          <div className="info-wrapper d-inline-flex align-items-center">
            <h5 className="text-black opacity-100 me-sm-3 me-2 mb-0">Chain Id</h5>
            <span className="text-white fw-medium value text-center d-inline-block">
              {targetTransaction?.chainID}
            </span>
          </div>
          <div className="info-wrapper d-inline-flex align-items-center">
            <h5 className="text-black opacity-100 me-sm-3 me-2 mb-0">Chain Name</h5>
            <span className="text-white fw-medium value text-center d-inline-block">
              {targetTransaction?.chainName}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentTransaction;
