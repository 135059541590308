/**
 * CopyRights
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React from 'react';

import './copyRights.css';

const CopyRights = () => {
  return (
    <section className="copy-rights text-center">
      <p className="mb-0">© 2023 Burn Wallet. All Rights Reserved.</p>
    </section>
  );
};

export default CopyRights;
