/**
 * DashBoard Page
 * Components for user interface elements such as sidebar, banner, sales report, contact requests, countries chart, refund requests, user profile, loader, and copyright section.
 * It utilizes React hooks for state management, Redux for global state, and checks user permissions for conditional rendering of specific content.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Sidebar from '../common/sidebar';
import Banner from './banner';
import SalesReport from './salesReport';
import ContactRequests from './contactRequests';
import CountriesChart from './countriesChart';
import RefundRequests from './refundRequests';
import UserPofile from '../common/userPofile';
import CopyRights from '../common/copyRights';
import Loader from '../common/loader';
import { logout } from '../../redux/features/adminSlice';
import './index.css';
import { PermissionDenied } from '../permissionDenied';

const Dashboard = () => {
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowed, setAllowed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dashboardPermissioncheck = () => {
    if (admin.rolePermission === '') {
      dispatch(logout());
      navigate('/');
    } else if (admin.rolePermission['viewDashboard'] === true) {
      setAllowed(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    dashboardPermissioncheck();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="main-wrapper dashboard d-flex">
        <Sidebar />
        <div className="content-container">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <UserPofile />
              {allowed === true ? (
                <>
                  <Banner />
                  <div className="d-lg-flex mb-lg-5">
                    <SalesReport />
                    <ContactRequests />
                  </div>
                  <div className="d-lg-flex mb-lg-5">
                    <CountriesChart />
                    <RefundRequests />
                  </div>
                </>
              ) : (
                <PermissionDenied /> // not allowed
              )}
            </>
          )}
          <CopyRights />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
